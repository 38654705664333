import story1 from "./../assets/images/Story_1.png";
import story2 from "./../assets/images/Story_2.png";
import story3 from "./../assets/images/Story_3.png";
import CDPLogo from "./../assets/images/CyberHover.png";
import BovaLogo from "./../assets/images/Bova.png";
import BharosaLogo from "./../assets/images/BharosaHover.png";
export const StoryItems = [
  {
    id: 0,
    imageUrl: story1,
    title:"Cyberstash",
    hoverUrl: CDPLogo,
    content:
      "Cyberstash - A Trusted Enterprise Security Solutions company based in Australia tasked Baelworks for building a State-of-the-art cybersecurity platform to help companies reduce their risk and exposure to cyber threats.",
  },
  {
    id: 1,
    imageUrl: story2,
    title:"BOVA Australia",
    hoverUrl: BovaLogo,
    content:
      "BOVA Australia is Australia’s largest Veterinary compounds and pharmacy companies with world class facilities for crafting pharmaceutical compounds for thousands of veterinarians, Baelworks is providing solutions for operations management and automation..",
  },
  {
    id: 2,
    imageUrl: story3,
    title:"Bharosa Agri Innovations",
    hoverUrl: BharosaLogo,
    content:
      "Bharosa Agri Innovations is an Innovation driver for solving and addressing various challenges in the Agri sector, Bharosa has a wide variety of solutions from creating data driven decision making for farmers to creating unique buyer and seller apps that leverage the path breaking ONDC framework, Bharosa is a unique experiment across various aspects of the Agri sector.",
  },
];
