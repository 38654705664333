export const mainNavbarItems = [
  {
    id: 0,
    label: "Services",
    route: "/services",
  },
  {
    id: 1,
    label: "Industries",
    route: "/industries",
  },
  // {
  //   id: 2,
  //   // label: "Training",
  //   // route: "/training",
  //   label: "Our Works",
  //   route: "/work",
  // },
  {
    id: 3,
    label: "About",
    route: "/about",
  },
];
