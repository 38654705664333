export const cardsDetail = [
    {
      id: 0,
      title: "Digital",
      description:
        "We have proprietary solutions and products for farm data collection, validation, integration and others. We combine the power of our deep-domain expertise in agriculture with our developers, engineers, data architects, and scientists across the technology spectrum.",
    },
    {
      id: 1,
      title: "Marketing & Sales",
      description:
        "We help agri stakeholders with commercial excellence through go-to-market strategy, pricing optimization, and sales execution.",
    },
    {
      id: 2,
      title: "Agri-processing",
      description:
        "We work with clients who are in the process of setting up new units or expand existing units by enabling them with financial products, subsidies, project planning and management.",
    },
    {
      id: 3,
      title: "Agri-advisory",
      description:
        "We are developing approaches that help farm operators and companies drive operational improvements.We are working with FPO’s adopt technology solutions and designing services to help them serve their stakeholders.",
    },
  ];