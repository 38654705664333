import React from "react";
import img1 from "./../../assets/images/Customers.png";
import img2 from "./../../assets/images/Bova_logo.png";
import img3 from "./../../assets/images/CDP_logo.png";
import img4 from "./../../assets/images/Rem42_logo.png";
import img5 from "./../../assets/images/Frieght_logo.png";
import img6 from "./../../assets/images/Peepal_logo.png";
import img7 from "./../../assets/images/Ahead_logo.png";

const Gallery = () => {
  return (
    <section className="my-3">
      <div className="container-fluid px-lg-5 px-md-4">
        <div className="section-heading py-3">
          <h2 className="ff-playfair fw-bold">Customers</h2>
        </div>
        <div className="d-flex align-items-stretch justify-content-xxl-between">
          <div className="d-flex align-content-stretch flex-wrap">
            <img src={img1} className="rounded-0 img-fluid" alt="galleryImg" />
          </div>
            <div className="d-flex align-content-stretch flex-wrap px-xxl-3">
            <div className="row g-xxl-3 g-xl-5 g-lg-2 g-md-2 g-3">
              <div className="col-4 bg">
                <div className="card h-100 bg-light-grey border-0 pt-0 rounded-0">
                  <a
                    className="py-xl-5 py-lg-4 text-center m-auto"
                    href="https://bovavet.com.au/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <div>
                      <img
                        src={img2}
                        className="rounded-0 img-fluid img-thumbnail bg-transparent border-0"
                        alt="galleryImg"
                      />
                    </div>
                  </a>
                </div>
              </div>
              <div className="col-4 bg">
                <div className="card h-100 bg-grey border-0 pt-0 rounded-0">
                  <a
                    className="py-xl-5 py-lg-4 text-center m-auto"
                    href="https://www.cyberstash.com/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <div>
                      <img
                        src={img3}
                        className="rounded-0 img-fluid img-thumbnail bg-transparent border-0"
                        alt="galleryImg"
                      />
                    </div>
                  </a>
                </div>
              </div>
              <div className="col-4 bg">
                <div className="card h-100 bg-light-grey border-0 pt-0 rounded-0">
                  <a
                    className="py-xl-5 py-lg-4 text-center m-auto"
                    href="https://www.rem42.ai/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <div>
                      <img
                        src={img4}
                        className="rounded-0 img-fluid img-thumbnail bg-transparent border-0"
                        alt="galleryImg"
                      />
                    </div>
                  </a>
                </div>
              </div>
              <div className="col-4 bg pb-0">
                <div className="card h-100 bg-grey border-0 rounded-0">
                  <a
                    className="py-xl-5 py-lg-2 text-center m-auto"
                    href="https://www.freighttiger.com/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <div>
                      <img
                        src={img5}
                        className="rounded-0 img-fluid img-thumbnail bg-transparent border-0"
                        alt="galleryImg"
                      />
                    </div>
                  </a>
                </div>
              </div>
              <div className="col-4 bg pb-0">
                <div className="card h-100 bg-light-grey border-0 rounded-0">
                  <a
                    className="py-xl-5 py-lg-2 text-center m-auto"
                    href="https://www.peepaldesign.com/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <div>
                      <img
                        src={img6}
                        className="rounded-0 img-fluid img-thumbnail bg-transparent border-0"
                        alt="galleryImg"
                      />
                    </div>
                  </a>
                </div>
              </div>
              <div className="col-4 bg pb-0">
                <div className="card h-100 bg-grey border-0 rounded-0">
                  <a
                    className="py-xl-5 py-lg-2 text-center m-auto"
                    href="https://aheadworks.com/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <div>
                      <img
                        src={img7}
                        className="rounded-0  img-fluid img-thumbnail bg-transparent border-0"
                        alt="galleryImg"
                      />
                    </div>
                  </a>
                </div>
              </div>
            </div>
            </div>
        </div>
      </div>
    </section>
  );
};

export default Gallery;
