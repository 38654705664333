import React from "react";
import { CarouselItems } from "../../Data/Carousel";
import Slider from "react-slick";
import "./Carousel.css";

const Carousel = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    pauseOnHover: false,
    cssEase: "linear",
  };
  return (
    <div className="d-flex justify-content-center py-2">
      <div className="carousel-container mt-n20 pt-2">
        <small className="text-white">.</small>
        {
          <Slider {...settings}>
            {CarouselItems.map((ele, ind) => {
              return (
                <div
                  key={ind}
                  className="slick-slider  justify-content-start"
                >
                  <img
                    key={ind}
                    src={ele.imageUrl}
                    alt=""
                    className="w-100 card-img"
                  />
                  <div
                    style={{ left: "1%" }}
                    className="row card-img-overlay g-lg-3 g-md-3 h-100 px-lg-2 pt-lg-3 pt-md-2" >
                    <div className="col-xxl-5 d-md-block col-lg-6 col-md-6 px-lg-5 bg-white">
                      <div className="d-flex h-100 flex-column justify-content-center">
                        <div className="p-2">
                          <h1 className="ff-playfair text-black">{ele.title}</h1>
                        </div>
                        <div className="p-2">
                          <p className="fs-5 text-black text-justify justify mb-lg-3 ff-montserrat">
                            {ele.description}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </Slider>
        }
      </div>
    </div>
  );
};

export default Carousel;
