import service1 from "./../assets/images/designbetter.png";
import service2 from "./../assets/images/buildbetter.png";
import service3 from "./../assets/images/sellbetter.png";
export const Services = [
  {
    id: 0,
    imageUrl: service1,
    title: "Design Better",
    content:
      "We Are A Team Of Passionate Designers, Innovators And Creators. Our Products, Solutions And Offerings Are Powered By A Deep Understanding Of The Users. We Have Strong Capabilities In “User Research”, “Experience Design”. We Also Work With Our Customers To Help Them With Our Design Advisory” Services To Help Them “Think Design”....",
  },
  {
    id: 1,
    imageUrl: service2,
    title: "Build Better",
    content:
      "With our team of developers and creators and our expertise on wide variety of technologies and platforms, we help you accomplish technology outcomes as products and services for your organizations. We also help existing teams with virtual CTO, architecture services....",
  },
  {
    id: 2,
    imageUrl: service3,
    title: "Sell Better",
    content:
      "We Work Together With You in Reaching Out To Your Customers And Users, Articulating, Defining, Communicating, Creating Copies, Reaching Out, Collecting Data, Analysing And Understanding Behaviours And Patterns, To Help You Sell Better. We use the Baelworks User Framework to help you with an end to end view while making decisions and taking actions....",
  },
];
