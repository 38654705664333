import React from "react";
import { Link, NavLink, useNavigate } from "react-router-dom";
import { mainNavbarItems } from "../../Data/Navbar";
import logo from "./../../assets/images/Baelworks_logo.png";
import "./navbar.css";
const scrollUp = () => {
  window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
};
const Navbar = () => {
  const contactus = () => {
    scrollUp();
    navigate("/contact");
  };
  const navigate = useNavigate();
  return (
    <nav
      className="navbar navbar-expand-lg navbar h-50 sticky-top navbar-light py-3"
      aria-label="Eighth navbar example"
    >
      <div className="container-fluid px-lg-5 px-md-4">
        <Link className="navbar-brand" to="/" onClick={scrollUp}>
          <img src={logo} alt="logo" />
        </Link>
        <button
          className="navbar-toggler"
          id="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarsExample07"
          aria-controls="navbarsExample07"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon" />
        </button>
        <div className="collapse navbar-collapse" id="navbarsExample07">
          <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
            {mainNavbarItems.map((item) => (
              <li
                className="nav-item me-5 text-black"
                key={item.id}
                onClick={() => {
                  scrollUp();
                  if (
                    window.matchMedia("screen and (max-width: 768px)").matches
                  ) {
                    document.getElementById("navbar-toggler").click();
                  }
                }}
              >
                <NavLink
                  to={item.route}
                  className={({ isActive }) =>
                    isActive ? "nav-link active" : "nav-link"
                  }
                  aria-current="page"
                >
                  {item.label}
                </NavLink>
              </li>
            ))}
          </ul>
          <div className="contact-us ms-lg-4 button">
            <button
              type="button"
              onClick={contactus}
              className="btn btn-primary rounded-0 fw-bold px-5 py-3 btn-lg"
            >
              <h6 className="mb-0 ff-montserrat">Contact Us</h6>
            </button>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
