import React from "react";
import IndustryCarousel from "../components/Carousel/IndustryCarousel";
// import Loader from './../components/Loader/Loader';
// import { useGetIndustryPageDataQuery } from "../Services/dataService";
import { useNavigate } from "react-router-dom";
import { industries_card } from "./../Data/IndustriesCards"

const Industries = () => {
  // const { isLoading } = useGetIndustryPageDataQuery();
  // if (isLoading) {
  //   return <Loader isLoading={isLoading}/>
  // }
  const navigate = useNavigate();

  function getToRoute(item) {
    localStorage.setItem("industryDetails", JSON.stringify(item));
    navigate("/industries/" + item.id);
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }


  return (
    <>
      <IndustryCarousel />
      <section className="my-4 pt-lg-5 pb-10">
        <div className="overflow-hidden container-fluid px-lg-5 px-md-4">
          <div className="row g-xxl-5">
            {industries_card.map((item, index) => (
              <div className="card-group col-lg-4" key={index}>
                <div className="card border-0 pe-none">
                  <div className="card-body">
                    <h5 className="card-title fs-2 fw-semibold ff-montserrat text-black">{item.heading}</h5>
                    <p className="card-text fs-5 fw-normal ff-montserrat text-justify text-black">
                      {item.description}
                    </p>
                  </div>

                  <div className="card-footer pe-auto bg-transparent border-0">
                    <small onClick={() => getToRoute(item)} className="fw-bold fs-5 text-color-primary ff-montserrat">
                      Read More
                    </small>
                  </div>

                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
    </>
  );
};

export default Industries;
