import React, { useState, useEffect } from 'react';
import Slider from "react-slick";
import { cardsDetail } from "../../Data/AgriCard"
import { EducationCard } from "../../Data/EducationCardd"
import phone1 from "../../assets/images/Phone_1.jpeg"
import phone2 from "../../assets/images/Phone_2.png"

const IndustryDetails = () => {
  // const navigate = useNavigate();
  const industryData = JSON.parse(localStorage.getItem("industryDetails"))
  const settings = {
    dots: false,
    infinite: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    pauseOnHover: false,
    cssEase: "linear",
  };

  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 770);
    };

    window.addEventListener('resize', handleResize);

    // Cleanup function to remove event listener
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  return (
    <><section className="container-fluid px-lg-5 px-md-4">
      <div className="d-flex justify-content-center py-2">
        <div className="carousel-container pt-2 w-100">
          <Slider {...settings}>
            <div className="slick-slider d-flex justify-content-start">
              <img
                src={industryData?.bgImage}
                alt="service"
                className="w-100 img-fluid"
              />
              <div className="row card-img-overlay g-lg-3 g-md-3 h-100 px-lg-2 pt-lg-3 pt-md-2">
                <div className="col-xxl-4 d-md-block col-lg-6 col-md-6 px-lg-5">
                  <div className={`d-flex h-100 flex-column justify-content-center ${isMobile ? 'background-blur' : industryData.textWhite ? "text-white" : "text-black"}`}>
                    <div className="p-2">
                      <h1 className="ff-playfair">
                        {industryData?.imgHeading}
                      </h1>
                    </div>
                    <div className="p-2">
                      <p className="fs-5 text-justify justify mb-lg-3 ff-montserrat">
                        {industryData?.imgContent}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Slider>
        </div>
      </div>
    </section>
      <section className="container-fluid px-lg-5 px-md-4 pt-lg-5" >
        <div className="d-flex flex-column bd-highlight mb-3">
          <div className="p-2 bd-highlight">
            <div className="ff-montserrat fs-2 text-black">
              {industryData?.head}
            </div>
          </div>
          <div className="p-2 bd-highlight text-black">
            <pre style={{ whiteSpace: "pre-wrap" }} className="ff-montserrat fs-5 text-black fw-normal text-justify">{industryData?.content}</pre>
          </div>
          {industryData?.otherImage &&           <div className="bd-highlight">
            <img src={industryData?.otherImage} className="img-fluid" alt="..." />
          </div>
          }

          {industryData?.imgHeading === "Manufacturing" && (
      <>
      <pre style={{ whiteSpace: "pre-wrap" }} className="ff-montserrat fs-5 text-black fw-normal text-justify">{industryData?.text}</pre>

      </>
    )}
          {industryData?.imgHeading === "Agriculture" && (
            <>
              <div class="d-flex px-3 fw-bold fs-3 ff-montserrat bd-highlight">What we do</div>
              <div>
                <div class="row card-group g-5" style={{ paddingTop: '3rem !important' }}>
                  {cardsDetail.map((item) => (

                    <div class="col-lg-3 col-md-3 col-sm-6 col-12" key={item.id}>
                      <div class="card py-3 border-0 card-hover rounded-0">
                        <div class="card-body">
                          <h5 class="card-title fs-4 fw-semibold ff-montserrat text-black">{item.title}</h5>
                          <p class="card-text text-justify fs-5 fw-normal ff-montserrat text-black">{item.description} </p>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>

              </div>
            </>
          )}  </div>
      </section>
      {industryData?.imgHeading === "Education" && (

        <>
          <section className='container-fluid px-lg-5 px-md-4'>
            <div class="d-flex bd-highlight align-content-cente flex-wrap align-self-center align-items-center py-lg-3">
              <div class="d-flex align-content-stretch flex-wrap">
                <img src={phone1} class="rounded-0 img-fluid" alt="galleryImg" />
              </div>
              <div class="d-flex align-content-stretch flex-wrap px-xxl-3">
                <div class="list-group border-0">

                  {EducationCard.slice(0, 8).map((item) => {
                    return (

                      <div class="list-group-item  border-0" aria-current="true">
                        <div class="d-flex w-100 justify-content-between">
                          <h5 class="mb-1 ff-montserrat fs-5">{item.head}</h5>
                        </div>
                        <p class="mb-1 ff-montserrat">{item.para}</p>
                      </div>
                    )
                  })}
                </div>
              </div>
            </div>
            <div class="d-flex bd-highlight align-content-center flex-wrap align-self-center align-items-center py-lg-3">
              <div class="d-flex align-content-stretch flex-wrap">
                <img src={phone2} class="rounded-0 img-fluid" alt="galleryImg" />
              </div>
              <div class="d-flex align-content-stretch flex-wrap px-xxl-3">
                <div class="list-group border-0">

                  {EducationCard.slice(8).map((item) => {
                    return (

                      <div class="list-group-item  border-0" aria-current="true">
                        <div class="d-flex w-100 justify-content-between">
                          <h5 class="mb-1 ff-montserrat fs-5">{item.head}</h5>
                        </div>
                        <p class="mb-1 ff-montserrat">{item.para}</p>
                      </div>
                    )
                  })}
                </div>
              </div>
            </div>
          </section>
        </>
       )}
   

    </>
  )
}

export default IndustryDetails