export const EducationCard = [
    {
        id: 1,
        head: "All Communications",
        para: "Coursework, Homework, Worksheets, Notices etc.",
    },
    {
        id: 2,
        head: "Achievements",
        para: "Certificates, Badges, Extra Curricular",
    },
    {
        id: 3,
        head: "Learning",
        para: "Online Quiz, External Competitions",
    },
    {
        id: 4,
        head: "School Records",
        para: "Results, time table, Attendance, Important Days",
    },
    {
        id: 5,
        head: "Security",
        para: "Visitor/ Guardian Management, Emergency Details, Login Management",
    },
    {
        id: 6,
        head: "Interactions",
        para: "2-Way interactions with teachers and staff, Surveys, Student Absent Notifications",
    },
    {
        id: 7,
        head: "Inclusiveness",
        para: "Hall of fame, Photo Gallery, teacher feedback, school feedback"
    },
    {
        id: 8,
        head: "Transactions",
        para: "Fees Management, Parents Corner, etc."
    },
    {
        id: 9,
        head: "Learning",
        para: "Curriculum Plan & Real Time Stress Assessment"
    },
    {
        id: 10,
        head: "Manage Workload",
        para: "Adhoc Tasks Management"
    },
    {
        id: 11,
        head: "Daily/ Weekly Schedule",
        para: "Full calendar view"
    },
    {
        id: 12,
        head: "School Records",
        para: "Online Attendance, Real time interaction with parents/ students"
    },
    {
        id: 13,
        head: "Interactions and Brand Security",
        para: "2 Way Student teacher interaction which can be controlled by Admin"
    },
    {
        id: 14,
        head: "Online leave management",
        para: "View, Apply, Cancel and Modify leaves on mobile app"
    },
    {
        id: 15,
        head: "Notifications",
        para: "Real time notifications to teachers/ employees"
    },
    {
        id: 16,
        head: "Increase Usage",
        para: "Leaderboards/ Gamifications"
    }
]