import React from "react";
import msg from "./../../assets/icons/Msg.png";
import linkedIn from "./../../assets/icons/LinkedInAbout.png";
import { ManagementTeam } from "../../Data/Experts"
import { useNavigate } from "react-router-dom";
import "./Expert.css";
const scrollUp = () => {
  window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
};
const Experts = () => {
  const navigate = useNavigate();
  function getToRoute(item) {
    localStorage.setItem("autherDetails", JSON.stringify(item));
    navigate("/detail/" + item.id);
    scrollUp();
  }
  return (
    <>
      <section>

        <div className="container px-lg-5 my-lg-5 my-md-4">
          <div >    {/*  key={item.id} */}
            <div className="content-align">
              <div className="d-flex bd-highlight fs-3 ff-montserrat text-color-primary fw-bold">
                <div className="d-flex">
                  <div className="px-3">
                    <h3 className="fw-bold fs-3 ff-montserrat pt-3" >Management Team</h3>
                  </div>
                </div>
              </div>
            </div>
            <div className="row g-xxl-5 g-lg-4 justify-content-center">
              {ManagementTeam.slice(0, 4).map((item) => (
                <div className="col-xxl-3 col-lg-3 col-md-6 px-xxl-4 card-group" key={item.id}>
                  <div className="card border-0 pe-none rounded-0 mb-5">
                    <div className="card-header border-0 bg-transparent px-0 fs-4 text-black ff-montserrat"></div>
                    <img
                      src={item?.imageUrl}
                      className="card-img-top rounded-0"
                      alt="Our CEO"
                    />
                    <div className="card-body px-0 pb-0">
                      <h5 className="card-title fs-2 ff-playfair fw-bold text-black">
                        {item?.name}
                      </h5>
                      <h6 className="ff-playfair h5 fw-medium text-black">
                        {item?.role}
                      </h6>
                    </div>
                    <div className="card-footer bg-transparent border-0 px-0 d-flex flex-row bd-highlight my-3">
                      <div className="bd-highlight pe-auto">
                        <a href={item?.profile} target="_blank" rel="noreferrer">
                          <img src={linkedIn} alt="social links" />
                        </a>
                      </div>
                      <div className="bd-highlight ms-4 pe-auto">
                        <a href={`mailto:${item?.mailId}`} >
                          {" "}
                          <img src={msg} alt="social links" />
                        </a>
                      </div>
                    </div>
           
                    <div
                      className="ps-0 border-0 bg-transparent d-flex flex-row bd-highlight justify-content-between"
                      onClick={() => getToRoute(item)}>
                      <div className="bd-highlight pe-auto fs-5 fw-bold text-color-primary ff-montserrat">
                        Read More
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>


          {/* Advisor */}

            {/* <div className="content-align">
              <div className="d-flex bd-highlight fs-3 ff-montserrat text-color-primary fw-bold">
                <div className="d-flex ">
                  <div className="px-3">
                    <h3 className="fw-bold fs-3 ff-montserrat" >Advisors</h3>
                  </div>
                </div>
              </div>
            </div> */}
            <div className="row g-xxl-5 g-lg-4 justify-content-center">
              { [].slice(3).map((item) => (
                <div
                  className="col-xxl-3 col-lg-3 col-md-6 px-xxl-4 card-group"
                  key={item.id}
                >
                  <div className="card border-0 pe-none rounded-0 mb-5">
                    <div className="card-header border-0 bg-transparent px-0 fs-4 text-black ff-montserrat"></div>
                    <p className="text-black fs-4  ff-montserrat" >{item.positionLabel}</p>
                    <img
                      src={item?.imageUrl}
                      className="card-img-top rounded-0"
                      alt="Our CEO"
                    />
                    <div className="card-body px-0 pb-0">
                      <h5 className="card-title fs-2 ff-playfair fw-bold text-black">
                        {item?.name}
                      </h5>
                      <h6 className="ff-playfair h5 fw-medium text-black">
                        {item?.role}
                      </h6>
                    </div>
                    <div className="card-footer bg-transparent border-0 px-0 d-flex flex-row bd-highlight my-3">
                      <div className="bd-highlight pe-auto">
                        <a href={item?.linkedInUrl} target="_blank" rel="noreferrer">
                          <img src={linkedIn} alt="social links" />
                        </a>
                      </div>
                      <div className="bd-highlight ms-4 pe-auto">
                        <a href={`mailto:${item?.mailId}`} >
                          {" "}
                          <img src={msg} alt="social links" />
                        </a>
                      </div>
                    </div>
                    {/* <p className="card-text ff-montserrat text-justify fs-5 text-black">
              {item?.aboutAuthorcontent.slice(0,250)}...
              </p> */}
                    <div
                      className="ps-0 border-0 bg-transparent d-flex flex-row bd-highlight justify-content-between"
                      onClick={() => getToRoute(item)}
                    >
                      <div className="bd-highlight pe-auto fs-5 fw-bold text-color-primary ff-montserrat">
                        Read More
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </section>

    </>
  );
};

export default Experts;
