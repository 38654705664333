import React from "react";
import gallery1 from "./../../assets/images/tofrum.png";
import gallery2 from "./../../assets/images/BharosaWork.png";
import gallery3 from "./../../assets/images/HappyHarda.png";
import gallery4 from "./../../assets/images/CDPwebsite.png";
import gallery5 from "./../../assets/images/Bovavet.png";
import gallery6 from "./../../assets/images/CyberStash.png";

const GallerySection = () => {
  return (
    <section className="my-lg-5 my-sm-2 container-fluid px-lg-5 px-md-4">
      <div className="section-heading py-3">
        <h2 className="ff-playfair fw-bold text-black">Works</h2>
      </div>
      <div className="row g-xl-5 g-md-3">
        <div className="col-lg-4 col-md-4 col-sm-12 col-12">
          <div className="card h-100 border-0 pe-none rounded-0">
            <img src={gallery1} className="card-img-top rounded-0" alt="..." />
            <div className="card-footer bg-transparent border-0 d-flex justify-content-between px-0">
              <span className="fs-5 fw-bold ff-montserrat text-black">Tofrum</span>
              {/* <span>
                <img src={arrows} className="img-fluid" alt="more info" />
              </span> */}
            </div>
          </div>
        </div>
        <div className="col-lg-4 col-md-4 col-sm-12 col-12">
          <div className="card h-100 border-0 pe-none rounded-0">
            <img src={gallery2} className="card-img-top rounded-0" alt="..." />
            <div className="card-footer bg-transparent border-0 d-flex justify-content-between px-0">
              <span className="fs-5 fw-bold ff-monserrat text-black">Bharosa</span>
              {/* <span>
                <img src={arrows} className="img-fluid" alt="more info" />
              </span> */}
            </div>
          </div>
        </div>
        <div className="col-lg-4 col-md-4 col-sm-12 col-12">
          <div className="card h-100 border-0 pe-none rounded-0">
            <img src={gallery3} className="card-img-top rounded-0" alt="..." />
            <div className="card-footer bg-transparent border-0 d-flex justify-content-between px-0">
              <span className="fs-5 fw-bold ff-monserrat text-black">Happy Harda</span>
              {/* <span>
                <img src={arrows} className="img-fluid" alt="more info" />
              </span> */}
            </div>
          </div>
        </div>
      </div>
      <div className="row gx-xl-5 g-md-3 mt-md-0 mt-xl-3">
        <div className="col-lg-4 col-md-4 col-sm-12 col-12">
          <div className="row flex-column g-xl-5 g-md-2">
            <div className="col-lg-12 col-12">
              <div className="card h-100 border-0 pe-none rounded-0">
                <img src={gallery4} className="card-img-top rounded-0" alt="..." />
                <div className="card-footer bg-transparent border-0 d-flex justify-content-between px-0">
                  <span className="fs-5 fw-bold ff-monserrat text-black">Cyberstash Platform</span>
                  {/* <span>
                    <img src={arrows} className="img-fluid" alt="more info" />
                  </span> */}
                </div>
              </div>
            </div>
            <div className="col-lg-12 col-12">
              <div className="card h-100 border-0 pe-none rounded-0">
                <img src={gallery5} className="card-img-top rounded-0" alt="..." />
                <div className="card-footer bg-transparent border-0 d-flex justify-content-between px-0">
                  <span className="fs-5 fw-bold ff-monserrat text-black">Bova AUS</span>
                  {/* <span>
                    <img src={arrows} className="img-fluid" alt="more info" />
                  </span> */}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-8 col-md-8 col-sm-12 col-12">
          <div className="card h-100 border-0 pe-none rounded-0">
            <img src={gallery6} className="card-img-top h-93 rounded-0" alt="..." />
            <div className="card-footer bg-transparent border-0 d-flex justify-content-between px-0">
              <span className="fs-5 fw-bold ff-monserrat text-black">Cyber Security Cloud Platform</span>
              {/* <span>
                <img src={arrows} className="img-fluid" alt="more info" />
              </span> */}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default GallerySection;
