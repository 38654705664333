import React from "react";
// import { useGetServicePageDataQuery } from "../Services/dataService";
// import Loader from "./../components/Loader/Loader";
import Slider from "react-slick";
import bg from "./../assets/images/service_bg.png"
import { Services } from "./../Data/Services";

const Service = () => {
  // const { data, isLoading } = useGetServicePageDataQuery();
  // if (isLoading) {
  //   return <Loader isLoading={isLoading} />;
  // }
  const settings = {
    dots: false,
    infinite: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    pauseOnHover: false,
    cssEase: "linear",
  };
  return (
    <>
      <div className="d-flex justify-content-center py-2">
        <div className="carousel-container pt-2 w-100">
          <Slider {...settings}>
            <div className={`slick-slider d-flex justify-content-start `}>
              <img
                src={bg}
                alt="service"
                className="w-100 img-fluid" />
              <div className="card-img-overlay row g-lg-3 g-md-3 h-100 px-lg-2 pt-lg-3 pt-md-2">
                <div className="col-xxl-4 d-md-block col-lg-6 col-md-6 px-lg-5">
                  <div className="d-flex h-100 flex-column justify-content-center">
                   <div className="background-blur">
                   <div className="p-2">
                      <h1 className="ff-playfair text-white">
                        Services
                      </h1>
                    </div>
                    <div className="p-2">
                      <p className="text-justify text-white justify mb-lg-3 ff-montserrat">
                        Explore with us the Innovation process, essential to build multiple futures, for you to then experience it and take informed 
                        decisions based on data. We also help create the right impact with our branding and marketing by bringing in diversity and 
                        perspective across our innovation framework.
                      </p>
                    </div>
                   </div>
                  </div>
                </div>
              </div>
            </div>
          </Slider>
        </div>
      </div>
      <section className="my-lg-4 my-md-3 pt-lg-5 pb-10">
        <div className="overflow-hidden container-fluid px-lg-5 px-md-4">
          <div className="card-group row g-xxl-5 g-lg-3 g-md-3 pb-lg-5">
            {Services.map((item, index) => (
              <div className="col-lg-4 col-md-4 col-sm-12" key={index}>
                <div className="card border-0 h-100 pe-none">
                  <img
                    src={item.imageUrl}
                    className="card-img-top"
                    alt="story1"
                  />
                  <div className="card-body px-0 text-justify pe-none ff-montserrat">
                    <div className="card-title fs-2 fw-semibold text-black">
                      {item.title}
                    </div>
                    <p className="card-text fs-5 fw-normal ff-montserrat text-black">
                      {item.content}
                    </p>
                  </div>
                  {/* <div className="card-footer border-0 bg-transparent ps-0 pe-auto">
                    <small className="fw-bold fs-5 text-color-primary ff-montserrat">
                      Read More
                    </small>
                  </div> */}
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
    </>
  );
};

export default Service;
