import React from "react";
import GallerySection from "../components/Gallery/Gallery";
import Gallery from "../components/Projects/Gallery";
import Projects from "../components/Projects/Projects";
import Stories from "../components/Stories/Stories";
import Carousel from "../components/Carousel/Carousel";

// import { useGetHomeDataQuery } from "../Services/dataService";
// import Loader from "../components/Loader/Loader";

const HomePages = () => {

  return (
    <>
      <Carousel />
      <Projects />
      <Gallery />
      <GallerySection />
      <Stories />
    </>
  )


  // const { data, isLoading: homeLoading } = useGetHomeDataQuery()
  // React.useEffect(() => {
  //   window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  // })
  
  // if (homeLoading) {
  //  return <Loader isLoading={homeLoading}/>
  // }
  // const homeData = data?.data.attributes
 
  // return (
  //   <div>
  //     {/* <CarousalSlick data={homeData?.carousel} /> */}
  //     <Carousel/>
  //     <Projects data={homeData?.aboutHome} />
  //     <Gallery />
  //     <GallerySection />
  //     <Stories data={homeData?.storiesContent}/>
  //   </div>
  // );
};

export default HomePages;
