import React from "react";
import { Link } from "react-router-dom";
import "./footer.css";
// import facebook from "./../../assets/icons/facebook.svg";
// import whatsapp from "./../../assets/icons/whatsapp.svg";
// import LinkedIn from "./../../assets/icons/linkedIn.png";
// import twitter from "./../../assets/icons/Twitter.svg";
import msg from "./../../assets/icons/msg.svg";
import copyright from "./../../assets/icons/copyright.png";
import pageUp from "./../../assets/icons/Page_up.svg";
import phone from "./../../assets/icons/phone.svg";

const scrollUp = () => {
  window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
};
const Footer = () => {
  return (
    <footer className="footer mt-auto py-3">
      <div className="container-fluid px-lg-5 px-md-4">
        <div className="d-flex bd-highlight align-items-center">
          <div className="p-2 w-100 bd-highlight ff-montserrat">
            <h5>Discuss a Challenge ?</h5>
            <h1 className="text-decoration-underline display-4 ff-montserrat fw-600">
              Let's talk
            </h1>
          </div>
          <Link className="p-2 flex-shrink-1 bd-highlight" onClick={scrollUp}>
            <img src={pageUp} alt="scrollToTop" />
          </Link>
        </div>
        <hr />
        <div className="my-5">
          <div className="row g-xxl-5 g-md-5 gx-sm-0">
            <div className="col-lg-4 col-md-4 col-sm-12">
              <div className="heading text-color-secondary mb-4 ff-montserrat">
                <h4>Contact</h4>
              </div>
              <div className="contact-list ff-montserrat">
                <p className="lh-lg">
                  <img src={msg} alt="msg" className="me-md-3 me-2" />
                  <span className="ff-montserrat">aditya@baelworks.com</span>
                </p>
                <p>
                  <img src={phone} alt="msg" className="me-md-3 me-2" />
                  <span className="ff-montserrat">+91 97313 99313</span>
                </p>
              </div>
            </div>
            <div className="col-lg-4 col-md-4 col-sm-12">
              <div className="row g-xxl-5 g-md-5 gx-sm-0 flex-column">
                <div className="col-12">
                  <div className="heading text-color-secondary mb-4 ff-montserrat">
                    <h4>Registered Address</h4>
                  </div>
                  <div className="sub-heading ff-montserrat">
                    <h6>Bangalore</h6>
                    <p className="fw-normal text-justify ff-montserrat">
                      SyNo: 159/1, 159/2, 160/1 Kannamangala Village, Whitefield
                      - Hoskote Rd, Bengaluru, Karnataka 560067, India
                    </p>
                  </div>
                </div>
                <div className="col-12">
                  <div className="heading text-color-secondary mb-4 ff-montserrat">
                    <h4>Australia Address</h4>
                  </div>
                  <div className="sub-heading ff-montserrat">
                    <h6>Australia</h6>
                    <p className="fw-normal text-justify ff-montserrat">
                      25 Angus Av, Epping, NSW 2120 24 /38-46 South St,
                      Rydalmere NSW 2116
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-4 col-sm-12">
              <div className="row g-4 flex-column">
                <div className="col-12">
                  <div className="heading text-color-secondary mb-4 ff-montserrat">
                    <h4>Development Center</h4>
                  </div>
                  <div className="sub-heading ff-montserrat">
                    <h6>Indore</h6>
                    <p className="fw-normal text-justify">
                      C2-403, Milan Heights, Piplyahana Road, Indore
                    </p>
                  </div>
                </div>
                {/* <div className="col-12">
                  <div className="sub-heading">
                    <h6>Ujjain</h6>
                    <p className="fw-normal text-justify">
                      C-21 Mall, 1st Floor, Nanakheda, Mahakal Vanijya, Sanwer
                      Road, Ujjain - 456001
                    </p>
                  </div>
                </div> */}
                <div className="col-12">
                  <div className="sub-heading ff-montserrat">
                    <h6>Sandalpur</h6>
                    <p className="fw-normal text-justify">
                      NH 59-A, Gram-Sandalpur Tehsil, Khategaon, Madhya Pradesh
                      455336, India
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <hr />
        <div className="d-md-flex flex-wrap justify-content-between align-items-center">
          <div className="p-2">
            <div>
              <img src={copyright} alt="msg" className="me-3" />
              <span className="ff-montserrat">Baelworks 2022</span>
            </div>
          </div>
          <div className="p-2">
            <div className="d-md-flex flex-nowrap bd-highlight ff-montserrat">
              <div className="bd-highlight mx-3">Cookie Policy</div>
              <div className="bd-highlight mx-3">Privacy Notice</div>
              {/* <div className="bd-highlight mx-3">baelworks.social</div> */}
            </div>
          </div>
          {/* <div className="p-2">
            <div>
              <Link href="" className="me-lg-4 me-md-2 me-3 text-reset">
                <img src={facebook} alt="facebook" />
              </Link>
              <Link href="" className="me-lg-4 me-md-2 me-3 text-reset">
                <img src={whatsapp} alt="whatsapp" />
              </Link>
              <a
                href="https://www.linkedin.com/company/baelworks/"
                target="_blank"
                className="me-lg-4 me-md-2 me-3 text-reset"
                rel="noreferrer"
              >
                <img src={LinkedIn} alt="Insta" />
              </a>
              <Link href="" className="me-lg-4 me-md-2 me-3 text-reset">
                <img src={twitter} alt="twitter" />
              </Link>
            </div>
          </div> */}
        </div>
      </div>
    </footer>
  );
};

export default Footer;
