import React, { useEffect } from "react";
import { Route, Routes, useNavigate } from "react-router-dom";
import { About } from "../../pages/About";
import ExpertsDetails from "../../pages/ExpertsDetails";
import HomePages from "../../pages/HomePages";
import Industries from "../../pages/Industries";
import Service from "../../pages/Service";
import Footer from "../Footer/Footer";
// import IndustryDetail from "../IndustryDetail/IndustryDetail";
import IndustryDetails from "../IndustryDetail/IndustryDetails";

import Navbar from "../Navbar/Navbar";
import ContactUs from './../../pages/ContactUs';
const UnknownURL = () => {
  const navigate = useNavigate();
  useEffect(() => {
    navigate(-1);
  }, [navigate]);
};
const Layout = () => {
  return (
    <div className="main">
      <Navbar />
      {/* <SocialIcons /> */}
      <Routes>
        <Route path="/" element={<HomePages />} />
        <Route path="/services" element={<Service />} />
        <Route path="/industries" element={<Industries />} />
        <Route path="/industries/:name" element={<IndustryDetails />} />
        <Route path="/about" element={<About />} />
        <Route path="/test" element={<IndustryDetails />} />
        {/* <Route path="/training" element={<Training />} /> */}
        <Route path="/detail" element={<ExpertsDetails />} />
        {/* <Route path="/training/technology" element={<Technology />} />
        <Route path="/training/design" element={<DesignPage />} />
        <Route path="/training/innovation" element={<Innovation />} />
        <Route path="/training/management" element={<Management />} /> */}
        {/* <Route path="/detail/1" element={<ExpertsDetails />} />
        <Route path="/detail/2" element={<ExpertsDetails2 />} />
        <Route path="/detail/3" element={<ExpertsDetails3/>} /> */}
                <Route path="/detail/:name" element={<ExpertsDetails />} />
        <Route path="/contact" element={<ContactUs/>} />
        <Route path="*" element={<UnknownURL />} />
      </Routes>
      <Footer />
    </div>
  );
};

export default Layout;
